
<div class="d-none d-xl-block">
  <div class="filters">
    <div class="breathe h-100 ">
      <app-atlas-fix-filter (selectedAreaChange)="searchArea($event)"></app-atlas-fix-filter>
    </div>
  </div>
</div><div class="container-fluid">
  <div
    class="row"
    *ngIf="atlasFixService.olMapService.datasets[0].filters[0].choices.length > 0"
  >
    <app-atlas-fix-map *isBrowser></app-atlas-fix-map>
  </div>
</div>
<div id="fix-filter">
  <div class="filter-container d-flex d-xl-none">
    <button class="btn btn-default" (click)="showFilter()"
    aria-label="filters">
      <fa-icon [icon]="icons.falFilter" class="mr-1"></fa-icon>
      {{ "ui.charts.filters" | translate }}
    </button>
  </div>
  <div class="filters side-filter" *ngIf="isShowFilter">
    <div class="container">
      <button class="close pt-2 pl-2" (click)="showFilter()"
      aria-label="Close filter bar">
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </button>
    </div>
    <app-atlas-fix-filter
      (selectedAreaChange)="searchArea($event)"
    ></app-atlas-fix-filter>
  </div>
</div>
<app-atlas-fix-legend></app-atlas-fix-legend>
<app-atlas-fix-description [data]="dataDescription"></app-atlas-fix-description>
