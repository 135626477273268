<mat-sidenav-container  [hasBackdrop]="false">
  <mat-sidenav
    mode="over"
    #sidenav
    position="end"
    [opened]="false"
    class="sidenav-filter pt-3"
  >
    <div class="w-100 h-100 p-3">
      <button class="close" (click)="sidenav.close()">
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="loader-overlay" [class.shown]="isLoading">
          <fa-icon
            [icon]="icons.falSpinnerThird"
            size="3x"
            [spin]="true"
          ></fa-icon>
        </div>
        <div
          class="d-flex d-lg-none justify-content-end align-items-center chart-controls w-100"
        >
          <button class="btn btn-default" (click)="sidenav.toggle()"
          aria-label="filters">
            <fa-icon [icon]="icons.falFilter" class="mr-1"></fa-icon>
            {{ "ui.charts.filters" | translate }}
          </button>
        </div>
        <div class="col-lg-9 col-md-12 chart">
          <div class="row position-relative flex-column">
            <div
              class="map-container"
              [class.loading]="isLoading"
              id="ftth-detail"
            >
              <div
                class="details-container"
                *ngIf="olMapService.details?.length > 0 || detailsLoading"
                role="contentinfo"
              >
                <button class="close" (click)="olMapService.details.length = 0"
                aria-label="Close detail bar">
                  <fa-icon [icon]="icons.falTimes"></fa-icon></button
                ><br />
                <ul *ngIf="olMapService.details">
                  <li *ngFor="let detail of olMapService.details">
                    <span class="label">{{
                      detail.label | label | async
                    }}</span>
                    <span class="value" *ngIf="detail.value != null">{{
                      detail.value
                    }}</span>
                    <span class="value" *ngIf="detail.ratio != null"
                      >{{
                        detail.ratio | multiply : 100 | number : "1.0-2"
                      }}%</span
                    >
                  </li>
                </ul>
                <fa-icon
                  *ngIf="detailsLoading"
                  class="details-loader"
                  [icon]="icons.falSpinnerThird"
                  size="2x"
                  [spin]="true"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
