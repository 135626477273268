<div class="container">
  <fa-icon class="loader" [class.shown]="!(atlasFixFilterService.atlasFixService.olMapService.filters[0].choices.length > 0)" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
  <ng-container *ngIf="atlasFixFilterService.atlasFixService.olMapService.filters[0].choices.length > 0">
    <app-area-select
      [(selectedArea)]="selectedArea"
      (selectedAreaChange)="onSearchArea($event)"
    ></app-area-select>
    <div >
      <mat-form-field *ngFor="let fil of atlasFixFilterService.atlasFixService.olMapService.filters" class="w-100">
        <mat-select
          [placeholder]="fil.label | translate"
          [ngModel]="atlasFixFilterService.atlasFixService.olMapService.filter.get(fil.key)"
          (ngModelChange)="setFilter(fil.key, $event)"
          [multiple]="fil.multiple"
          aria-haspopup="dialog"
          role="listbox"
          saveInputValue="short">
          <mat-option
            *ngFor="let choice of fil.choices"
            [value]="choice.value"
            >{{ choice.label | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</div>
