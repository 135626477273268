import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { faFacebookF, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { IndicatorsService } from '../indicators.service';
import { HttpParams } from '@angular/common/http';
import { unsubscribe } from '../../../utils/helpers';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import * as ClipboardJS from 'clipboard';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'social-buttons',
  template: `
    <div class="d-flex">
      <button type="button" class="social-button" #cc [attr.data-clipboard-text]="url" [matTooltip]="ccText | translate" aria-label="connection link">
        <fa-icon [icon]="icons.cc"></fa-icon>
      </button>
      <button type="button" (click)="shareOnFacebook()" class="social-button" aria-label="facebook">
        <fa-icon [icon]="icons.fb"></fa-icon>
      </button>
      <button type="button" (click)="tweet()" class="social-button" aria-label="x">
        <fa-icon [icon]="icons.twitter"></fa-icon>
      </button>
      <button type="button" (click)="shareOnLinkedIn()" class="social-button" aria-label="linkedin">
        <fa-icon [icon]="icons.linkedIn"></fa-icon>
      </button>
    </div>
  `,
  styleUrls: [`./social-buttons.component.scss`],
})
export class SocialButtonsComponent implements OnDestroy {

  private readonly subscriptions: Subscription[] = [];

  private clipBoard: any;

  @ViewChild(MatTooltip) tooltip: MatTooltip;

  @ViewChild('cc', { static: true })
  private set ccElement(val: ElementRef) {
    this.clipBoard = new ClipboardJS(val.nativeElement);
    this.clipBoard.on('success', () => {
      this.ccText = 'general.copied';
      this.tooltip.show();
      setTimeout(() => {
        this.ccText = 'general.copy';
      }, 3000);
    });
  }

  url: string;
  ccText = 'general.copy';

  ngOnDestroy() {
    unsubscribe(this.subscriptions);
    if (this.clipBoard) {
      this.clipBoard.destroy();
    }
  }

  readonly icons: any = {
    fb: faFacebookF,
    twitter: faTwitter,
    linkedIn: faLinkedin,
    cc: faLink,
  };

  constructor(
    private readonly indicatorService: IndicatorsService,
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.indicatorService.valueChanges.subscribe(val => {
        const url = window.location.href + '?' + new HttpParams({ fromObject: val }).toString();
        this.url = url
      }),
    );
  }

  shareOnFacebook() {
    const url = `https://www.facebook.com/bipt.ibpt/`;
    const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus();
    }
  }

  tweet() {
    const url = 'https://twitter.com/bipt_ibpt';
    const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus();
    }
  }

  shareOnLinkedIn() {
    const url = 'https://www.linkedin.com/company/bipt/';
    const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus();
    }
  }

}
