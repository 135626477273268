<div class="d-none d-xl-block">
  <div class="filters">
    <div class="breathe">
      <app-atlas-trein-filter
        (selectedAreaChange)="searchArea($event)"
      ></app-atlas-trein-filter>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <app-atlas-trein-map></app-atlas-trein-map>
  </div>
</div>
<div id="trein-filter">
  <div class="filter-container d-flex d-xl-none">
    <button
      class="btn btn-default"
      (click)="showFilter()"
      aria-label="filters"
    >
      <fa-icon [icon]="icons.falFilter" class="mr-1"></fa-icon>
      {{ "ui.charts.filters" | translate }}
    </button>
  </div>
  <div class="filters side-filter" *ngIf="isShowFilter">
    <div class="container">
      <button
        class="close pt-2 pl-2"
        (click)="showFilter()"
        aria-label="Close filter bar"
      >
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </button>
    </div>
    <app-atlas-trein-filter
      (selectedAreaChange)="searchArea($event)"
    ></app-atlas-trein-filter>
  </div>
</div>
<app-atlas-trein-legend></app-atlas-trein-legend>
<app-atlas-trein-description
  [data]="dataDescription"
></app-atlas-trein-description>
