<mat-form-field class="w-100">
  <span class="mat-form-field-label-wrapper">
    <label #codeLabel for="searchPostcode" class="mat-form-field-label mat-empty mat-form-field-empty">
      {{ "controls.postcode" | translate }}</label>
  </span>
  <input type="text"
  #searchPostCode
  id="searchPostcode"
  matInput
  [formControl]="searchPostcodeForm"
  [matAutocomplete]="auto"
  (focus)="onFocus($event)"
  >
  <!--(keydown.backspace)="clear()"-->
  <mat-autocomplete #auto="matAutocomplete"
  [displayWith]="getOptionText"
  (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true"
  aria-label="searchPostcode"
  >
  <mat-option *ngFor="let result of results" [value]="result"
    [id]="generateOptionId(result)"
    [attr.aria-selected]="isOptionSelected(result) ? 'true' : 'false'"
    [tabindex]="-1"
    (mouseenter)="updateActiveOption(generateOptionId(result))"
    (mouseleave)="updateActiveOption(null)">
    {{result.name_fr}} 
    {{result.name}}  
    {{result.postcode}}
  </mat-option>
</mat-autocomplete>
<fa-icon class="loader" [class.shown]="loading" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
</mat-form-field>

<mat-form-field class="w-100">
  <span class="mat-form-field-label-wrapper">
    <label #areaLabel for="searchArea" class="mat-form-field-label mat-empty mat-form-field-empty">
      {{ "controls.area" | translate }}</label>
  </span>
  <input type="text"
  #searchArea
  id="searchArea"
  matInput
  [formControl]="searchStreetForm"
  [matAutocomplete]="auto2"
  (focus)="onFocus($event)"
  >
  <!--(keydown.backspace)="clear()"-->
  <mat-autocomplete #auto2="matAutocomplete"
  [displayWith]="getOptionText"
  (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true"
  aria-label="searchArea"
  >
  <mat-option *ngFor="let result2 of results2" [value]="result2"
    [id]="generateOptionId(result2)"
    [attr.aria-selected]="isOptionSelected(result2) ? 'true' : 'false'"
    [tabindex]="-1"
    (mouseenter)="updateActiveOption(generateOptionId(result2))"
    (mouseleave)="updateActiveOption(null)">
      {{result2.name}}
    </mat-option>
  </mat-autocomplete>
  <fa-icon class="loader" [class.shown]="loading" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
</mat-form-field>
