import { LayoutModule } from "@angular/cdk/layout";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarModule } from "ng-sidebar";
import { AreaSelectComponent } from "../components/area-select/area-select.component";
import { LabelPipe } from "../pipes/label.pipe";
import { MultiplyPipe } from "../pipes/multiply.pipe";
import { HyphenatePipe } from "../pipes/hyphenate.pipe";

const components = [AreaSelectComponent];
const pipes = [LabelPipe, MultiplyPipe, HyphenatePipe];
@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    TranslateModule,
    NoopAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatButtonModule,
    MatRadioModule,
    MatSliderModule,
    LayoutModule,
    SidebarModule.forRoot(),
    MatMenuModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatIconModule,
    ScrollingModule,
    MatTooltipModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
  ],
  exports: [
    TranslateModule,
    NoopAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatButtonModule,
    MatRadioModule,
    MatSliderModule,
    LayoutModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatIconModule,
    ScrollingModule,
    MatTooltipModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    AreaSelectComponent,
    LabelPipe,
    MultiplyPipe,
    HyphenatePipe
  ],
})
export class SharedModule {}
