<div class="d-flex justify-content-center" *ngIf="loading; else __loaded">
  <app-loader></app-loader>
</div>

<ng-template #__loaded>
  <ng-container *ngIf="error; else __ok">
    <div class="alert alert-danger">{{'indicators.error.generic' | translate}}</div>
  </ng-container>
  <ng-template #__ok>
    <div class="animated fadeIn w-100 h-100">


      <div>
        <input type="hidden" [(ngModel)]="selectedSubjects" saveInputValue="subject"/>
        <app-chips-autocomplete [placeholder]="'indicators.subject' | translate"
                                [choices]="subjects"
                                [(selectedOptions)]="selectedSubjects"
        ></app-chips-autocomplete>
      </div>

      <div>
        <input type="hidden" [(ngModel)]="selectedPeriods" saveInputValue="period"/>
        <app-chips-autocomplete [placeholder]="'indicators.period' | translate"
                                [choices]="periods"
                                [(selectedOptions)]="selectedPeriods"
        ></app-chips-autocomplete>
      </div>

      <div>
        <mat-form-field class="w-100">
          <mat-label>{{'filters.chartType.title' | translate}}</mat-label>
          <mat-select [(ngModel)]="chartType" (ngModelChange)="onChartTypeChange()" saveInputValue="chartType"
            aria-haspopup="dialog"
            role="listbox"
            saveInputValue="short">
            <mat-option *ngFor="let option of chartTypes" [value]="option.value">
              {{option.label | translate}}
            </mat-option>
            <mat-option *ngIf="chartTypes.length <= 0" [disabled]="true">No data</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedSubjects && selectedPeriods && indicator" class="mt-3">
        <app-indicator-chart-component
          [short]="indicator.short"
          [breakdown]="indicator.breakdown"
          [unit]="indicator.unit"
          [values]="indicatorValues"
          [subjects]="selectedSubjects"
          [periods]="selectedPeriods"
          [chartType]="chartType"
        ></app-indicator-chart-component>
      </div>

    </div>
  </ng-template>
</ng-template>
