<ng-container *ngIf="data">
    <h2>{{ "general.descriptions.title.description" | translate }}</h2>
  
    <div
      class="description mt-3"
      [innerHTML]="getDescription() | translate"
    ></div>
  
    <h2>{{ "general.descriptions.title.information" | translate }}</h2>
  
    <div class="metadata">
      <div class="metadata-row">
        <div class="label">{{ "general.descriptions.providedBy" | translate }}</div>
        <div class="value">
          {{ data.metadata.dataProvidedBy || "general.notApplicable" | translate }}
        </div>
      </div>
      
      <div class="metadata-row">
        <div class="label">{{ "general.descriptions.lastValidatedAt" | translate }}</div>
        <div class="value capitalize">
          {{
            data.metadata.lastValidatedAt
              ? getLocalizedDate(data.metadata.lastValidatedAt)
              : data.metadata.lastValidated
              ? (data.metadata.lastValidated | translate)
              : "N/A"
          }}
        </div>
      </div>
    </div>
  </ng-container>
  