<div class="container">
  <fa-icon class="loader" [icon]="icons.falSpinnerThird" [spin]="true">
  </fa-icon>
  <ng-container [formGroup]="form" #filterContainer>
    <app-area-select
      [(selectedArea)]="selectedArea"
      (selectedAreaChange)="onSearchArea($event)"
    ></app-area-select>
    <div>
      <mat-form-field
        *ngFor="let control of form.controls | keyvalue"
        class="w-100"
      >
        <mat-select [placeholder]="formLabels[control.key] | translate" [formControlName]="control.key"
          aria-haspopup="dialog"
          role="listbox"
          saveInputValue="short">
          <mat-option
            *ngFor="let option of options[control.key]"
            [value]="option"
          >
            {{ "labels."+option | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</div>
