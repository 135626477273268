<div class="">
  <!-- <div class="container-fluid page-header bg-1">
    <h1>{{ 'home.title' | translate }}</h1>
    <h2 class="subtitle">{{ 'home.subtitle' | translate }}</h2>
  </div> -->

  <div class="container-fluid data-sources">
    <div class="container breathe">
      <div class="row no-gutters">
        <div
          class="home-card-wrapper col-xl-4 col-lg-4 col-md-6 col-sm-12 p-3"
          *ngFor="let project of projects"
          [class.d-none]="!project.routerLink"
        >
          <div
            class="home-card"
            role="button"
            tabindex="0"
            routerLink="{{ lang }}/{{ project.routerLink }}"
            [class.disabled]="!project.routerLink"
            (click)="selectProject(project)"
          >
            <div class="big-icon" *ngIf="project.bigIcon">
              <!--<div class="corner" [style.background]="projec.cornerBgColor"></div>-->
              <div
                class="corner"
                [style.background]="project.cornerBgColor"
              ></div>
              <fa-icon [icon]="project.bigIcon"></fa-icon>
            </div>
            <div class="title">{{ project.title | translate }}</div>
            <div class="description">{{ project.description | translate }}</div>
            <!--<div class="coming-soon" *ngIf="!projec.routerLink">-->
            <!--<span class="badge badge-primary">Coming soon</span>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
