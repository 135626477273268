<footer class="d-flex justify-content-center align-items-center flex-wrap">
  <div class="text footer-text">
    {{ 'footer.bybipt' | translate }}
    <a href="https://www.ibpt.be" target="_blank" title="Opens in a new window"><strong>{{ 'footer.bipt' | translate }}</strong></a>
  </div>
  <div class="ml-2 mr-2 sep">|</div>
  <!-- <div class="d-flex text">
    <div class="mr-1">
      {{'footer.poweredby' | translate}}
    </div>
    <a href="https://www.charlie-bravo.be" target="_blank" class="d-flex align-items-center">
      <strong>Charlie Bravo</strong>
    </a>
  </div>
  <div class="ml-2 mr-2 sep">|</div> -->
  <div class="text">
    <a [appLangRouterLink]="['terms']">{{ 'footer.terms' | translate }}</a>
  </div>
</footer>
