<div
  class="container breathe mt-5"
  *ngIf="subjectSummary?.tableName && subjectDataSummary?.tableName"
>
  <div class="row datasets">
    <div
      class="col dataset p-3"
      *ngFor="let entry of tabNames"
      [class.active]="selectedData === entry"
      (click)="onSelectionChange(entry)"
    >
      {{ entry }}
    </div>
  </div>
  <div class="centered mt-5 breathe">
    <a
      class="btn btn-lg btn-primary"
      href="{{ 'ui.projects.drivetesting.download-report-link' | translate }}"
      target="_blank"
    >
      {{ "ui.projects.drivetesting.download-report" | translate }}
      <fa-icon [icon]="icons.falFilePdf" class="ml-2"></fa-icon>
    </a>
  </div>

  <table class="wide d-none d-md-table">
    <tr class="column-titles">
      <th class="title" scope="col" colspan="2">{{ subjectSummary.tableName }}</th>
      <th 
          *ngFor="let operator of subjectSummary.providers"
          [attr.id]="operator"
          [className]="'data-column ' + operator | lowercase"
          scope="col">
        {{ operator }}
      </th>
    </tr>
  
    <ng-container *ngFor="let category of subjectSummary.categories">
      <tr class="inbetween-title" [attr.headers]="category.data.header">
        <th colspan="5" scope="col">{{ category.subTable }}</th>
      </tr>
  
      <tr class="data-row" *ngFor="let row of category.data" [attr.headers]="category.data.header">
        <th class="data-row-title" 
            [attr.id]="row.header | hyphenate" 
            scope="row">
          {{ row.header }}
        </th>
        <td class="data-row-unit" 
            [attr.headers]="row.header | hyphenate">
          {{ row.ratio }}
        </td>
        <td class="data-column"
            *ngFor="let value of row.values;let j = index"
            [attr.headers]="subjectSummary.providers[j]">
          {{ value }}
        </td>
      </tr>
    </ng-container>
  </table>

  <table class="not-wide d-table d-md-none">
    <tr class="table-title">
      <th colspan="3" scope="col">{{ subjectSummary.tableName }}</th>
    </tr>

    <ng-container *ngFor="let category of subjectSummary.categories">
      <tr class="category-title">
        <th colspan="3" scope="col">{{ category.subTable }}</th>
      </tr>

      <tr class="operators">
        <th
          *ngFor="let operator of subjectSummary.providers"
          id="{{category.subTable+'-'+operator | hyphenate}}"
          [class]="operator | lowercase"
          scope="col"
        >
          {{ operator }}
        </th>
      </tr>

      <ng-container *ngFor="let row of category.data;let i = index">
        <tr class="data-title">
          <th colspan="3" [attr.id]="(row.header+'-mini') | hyphenate" scope="row">
            {{ row.header }} <span class="data-unit">({{ row.ratio }})</span>
          </th>
        </tr>

        <tr class="data-row">
          <td *ngFor="let value of row.values;let j = index"
            [attr.header]="(row.header+'-'+subjectSummary.providers[j]) | hyphenate">
            {{ value }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>

  <h3 class="mt-5">Data results</h3>

  <table class="wide d-none d-md-table">
    <tr class="column-titles">
      <th class="title" scope="col" colspan="3">{{ subjectDataSummary.tableName }}</th>
      <th
        *ngFor="let operator of subjectDataSummary.providers"
        id="{{(subjectDataSummary.tableName + operator) | hyphenate}}"
        [className]="'data-column ' + operator | lowercase"
        scope="col">
        {{ operator }}
      </th>
    </tr>

    <ng-container *ngFor="let category of subjectDataSummary.categories">
      <tr class="inbetween-title">
        <th colspan="6" scope="col">{{ category.subTable }}</th>
      </tr>

      <ng-container *ngFor="let row of category.rows">
        <tr class="data-row" *ngFor="let subRow of row.subRows; let i = index">
          <th
            class="data-row-title"
            *ngIf="i === 0"
            [attr.rowspan]="row.subRows.length"
            [attr.id]="row.header | hyphenate" 
            scope="row"
          >
            {{ row.header }}
          </th>
          <td
            class="data-row-subcategory"
            *ngIf="i === 0 || row.subRows.length === row.info.length"
            [attr.rowspan]="row.subRows.length / row.info.length"
          >
            {{ row.info[i] }}
          </td>
          <td class="data-row-unit">{{ subRow.ratio }}</td>
          <ng-container *ngFor="let value of subRow.values">
            <td class="data-column">{{ value }}</td>
          </ng-container>
        </tr>
      </ng-container>
    </ng-container>
  </table>

  <table class="not-wide d-table d-md-none">
    <tr class="table-title">
      <th colspan="3"  scope="col">{{ subjectDataSummary.tableName }}</th>
    </tr>

    <tr class="operators">
      <th
        *ngFor="let operator of subjectDataSummary.providers"
        [class]="operator | lowercase"
        scope="col"
      >
        {{ operator }}
      </th>
    </tr>

    <ng-container *ngFor="let category of subjectDataSummary.categories">
      <tr class="category-title">
        <th colspan="3" [attr.id]="(category.subTable+'-mini') | hyphenate" scope="row"
        >{{ category.subTable }}</th>
      </tr>

      <ng-container *ngFor="let row of category.rows">
        <tr class="row-title">
          <th colspan="3" [attr.id]="(row.header+'-mini') | hyphenate" scope="row">
            {{ row.header }}
            <ng-container *ngIf="row.info.length === 1">
              <br />
              <span class="altlabel">{{ row.info[0] }}</span>
            </ng-container>
          </th>
        </tr>

        <ng-container *ngFor="let subRow of row.subRows; let i = index">
          <tr class="data-title">
            <th colspan="3" [attr.id]="(row.header+'-'+row.info[i]) | hyphenate" scope="row">
              <ng-container *ngIf="row.subRows.length === row.info.length">
                <span class="subrow-label">{{ row.info[i] }}</span>
                <br />
              </ng-container>
              {{ subRow.ratio }}
            </th>
          </tr>

          <tr class="data-row">
            <td *ngFor="let value of subRow.values;let j = index"
            [attr.header]="(row.header+'-'+subjectSummary.providers[j]) | hyphenate">{{ value }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </table>

  <div class="centered mt-5">
    <a
      class="btn btn-lg btn-primary"
      href="{{ 'ui.projects.drivetesting.download-report-link' | translate }}"
      target="_blank"
    >
      {{ "ui.projects.drivetesting.download-report" | translate }}
      <fa-icon [icon]="icons.falFilePdf" class="ml-2"></fa-icon>
    </a>
  </div>
</div>
