import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';

// https://stackoverflow.com/questions/49131094/is-there-a-way-to-make-a-multiselection-in-autocomplete-angular4/51467701
@Component({
  selector: 'app-chips-autocomplete',
  styles: [`
    :host {
      position: relative;
    }

    .chip-remove-icon {
      position: relative;
      bottom: -2px;
    }

    .loader {
      position: absolute;
      right: 0;
      bottom: 5px;
    }

    .loader:not(.shown) {
      display: none;
    }

    .btn-links {
      position: relative;
      top: -15px;
    }

    .btn-link:hover {
      text-decoration: underline;
    }
    .placeholder-label {
      position: absolute;
      top:0px;
      left:-5px;
      pointer-events: none;
      color: #0009;
      font-family: "Nunito Sans", "Open Sans", sans-serif !important;
      font: inherit;
      white-space: nowrap;
      transform: scale(0.75) perspective(100px) translateZ(0.001px);
    }
    .placeholder-active {
      position:relative;
      font-size: 16px;
      transform: none;
      top:0px;
      left:0px;
    }
    .input-active{
      color: #673ab7;
      position: absolute;
      top:0px;
      left:-5px;
      transform: scale(0.75) perspective(100px) translateZ(0.001px);
    }

  `],
  template: `
    <mat-form-field class="w-100">
    <mat-chip-list #chipList role="listbox" [attr.aria-label]="chipList.id">
        <mat-chip
          *ngFor="let val of selectedOptions"
          (removed)="remove(val)">
          {{val}}
          <fa-icon [icon]="icons.delete" matChipRemove class="ml-2 chip-remove-icon">cancel</fa-icon>
        </mat-chip>
        <span class="mat-form-field-label-wrapper">
          <label #label for="{{input.id}}" class="placeholder-label" [ngClass]="{ 'placeholder-active': _selectedOptions.length === 0 }">
            {{ placeholder }}</label>
        </span>
        <input
          #input
          [formControl]="control"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selected($event)"
                        [autoActiveFirstOption]="true"
                        aria-label="Select an option"
                        (opened)="onAutocompleteOpened()"
                        (closed)="onAutocompleteClosed()"  
      >
        <cdk-virtual-scroll-viewport itemSize="48" style="height: 256px">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option" [tabindex]="-1" 
          [id]="generateOptionId(option)"
          [attr.aria-selected]="isOptionSelected(option) ? 'true' : 'false'"
          (mouseenter)="updateActiveOption(generateOptionId(option))"
          (mouseleave)="updateActiveOption(null)">
            {{option}}
          </mat-option>
          <mat-option *ngIf="((filteredOptions | async) || []).length <= 0" [disabled]="true">No data</mat-option>
          <mat-option *ngIf="isResultCropped" [disabled]="true">Some results were not displayed, please be more specific in your search</mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
      <fa-icon class="loader" [class.shown]="loading" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
    </mat-form-field>
    <div class="btn-links">
      <a (click)="selectAll()" class="btn-link mr-3">{{'indicators.buttons.selectAll' | translate}} ({{placeholder.toLowerCase()}})</a>
      <a (click)="unselectAll()" class="btn-link">{{'indicators.buttons.unselectAll' | translate}} ({{placeholder.toLowerCase()}})</a>
    </div>
  `
})
export class ChipsAutocompleteComponent implements OnChanges {

  @Input() choices: string[] = [];
  @Input() placeholder: string;

  public _selectedOptions: string[] = [];
  @Output() selectedOptionsChange = new EventEmitter();

  get selectedOptions(): string[] {
    return this._selectedOptions;
  }

  @Input()
  set selectedOptions(vals: string[]) {
    this._selectedOptions = vals;
    this.selectedOptionsChange.emit(vals);
  }

  readonly icons = {
    delete: faTimes,
    falSpinnerThird: faSpinnerThird,
  };
  separatorKeysCodes: number[] = [ENTER, COMMA];
  control = new FormControl(null);
  availableChoices: string[] = [];
  filteredOptions: Observable<string[]>;
  maxResults = 10;

  loading = false;
  hasTerms = false;
  isResultCropped = false;

  @ViewChild('input') input: ElementRef;
  @ViewChild('label') label: ElementRef;

  constructor(private renderer: Renderer2) {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(null as string),
      tap(val => this.hasTerms = !!val),
      debounceTime(300),
      distinctUntilChanged((x, y) => !!x && !!y && x === y),
      tap(() => this.loading = true),
      map((terms: string | null) => {
        if (terms && terms.length >= 3) {
          return this.filter(terms);
        }
        const cropped = this.availableChoices.slice(0, 50);
        if (cropped.length !== this.availableChoices.length) {
          this.isResultCropped = true;
        }
        return cropped;
      }),
      tap(() => this.loading = false),
    );
  }
  activeOptionId: string | null = null;

  ngAfterViewInit() {
    this.renderer.removeAttribute(this.input.nativeElement, 'autocomplete');
    this.renderer.listen(this.input.nativeElement, 'focus', () => {
      this.label.nativeElement.classList.add('input-active', 'placeholder-label');
    });
    this.renderer.listen(this.input.nativeElement, 'blur', () => {
      this.renderer.removeClass(this.label.nativeElement, 'input-active');
    });
    this.renderer.setAttribute(this.input.nativeElement, 'role', 'combobox');
    this.renderer.setAttribute(this.input.nativeElement, 'aria-haspopup', 'listbox');
    this.renderer.setAttribute(this.input.nativeElement, 'aria-autocomplete', 'list');
    this.renderer.setAttribute(this.input.nativeElement, 'aria-expanded', 'false');
  }

  onAutocompleteOpened(): void {
    this.renderer.setAttribute(this.input.nativeElement, 'aria-expanded', 'true');
  }

  onAutocompleteClosed(): void {
    this.renderer.setAttribute(this.input.nativeElement, 'aria-expanded', 'false');
    this.renderer.removeAttribute(this.input.nativeElement, 'aria-activedescendant');
  }

  updateActiveOption(optionId: string): void {
    if (optionId) {
      document.getElementById(optionId).setAttribute('aria-selected','true');
      this.renderer.setAttribute(this.input.nativeElement, 'aria-activedescendant', optionId);
    }
    else{
      this.renderer.removeAttribute(this.input.nativeElement, 'aria-activedescendant');
    }
  }
  generateOptionId(option: string): string {
    return `option-${option.toLowerCase().replace(/\s/g, '-').replace(/[^\w-]/g, '')}`;
  }
  isOptionSelected(option: string): boolean {
    return this.activeOptionId === this.generateOptionId(option);
  }
  private resetAvailableChoices() {
    this.availableChoices = this.choices
      .filter(val => this.selectedOptions.indexOf(val) === -1);
    this.control.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    const value = event.value;

    if (!(value || '').trim() || this.availableChoices.indexOf(value) === -1) {
      return;
    }

    this.availableChoices = this.availableChoices.filter(val => val !== value);
    this.selectedOptions.push(value);
    this.control.setValue(null);
    event.input.value = '';
  }

  remove(val: string): void {
    this.selectedOptions = [...this.selectedOptions.filter(option => option !== val)];
    this.resetAvailableChoices();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions = [...this.selectedOptions, event.option.value];
    this.availableChoices = this.availableChoices.filter(val => val !== event.option.value);
    this.control.setValue(null);
    this.input.nativeElement.value = '';
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    const results = this.availableChoices
      .filter(val => !this.control.value || val !== this.control.value)
      .filter(val => val.toLowerCase().indexOf(filterValue) === 0);

    const croppedResults = results.slice(0, this.maxResults);

    this.isResultCropped = results.length !== croppedResults.length;

    return croppedResults;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['choices']) {
      this.resetAvailableChoices();
    }
  }

  selectAll() {
    this.selectedOptions = [...this.choices];
    this.resetAvailableChoices();
  }

  unselectAll() {
    this.selectedOptions = [];
    this.resetAvailableChoices();
  }
}
