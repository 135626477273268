
<div class="d-none d-xl-block">
  <div class="filters">
    <div class="breathe">
      <app-atlas-ftth-filter [lastQuarterDataAvailable]="atlasFtthSetvice.lastQuarterDataAvailable"
      (selectedAreaChange)="searchArea($event)"> </app-atlas-ftth-filter>
    </div>
  </div>
</div><div class="container-fluid">
  <div
    *ngIf="olMapService.datasets[0].filters[0].choices.length > 0">
    <app-atlas-ftth-map *isBrowser></app-atlas-ftth-map>
  </div>
</div>
<div id="ftth-filter">
  <div class="filter-container d-flex d-xl-none">
    <button class="btn btn-default" (click)="showFilter()"
    aria-label="filters">
      <fa-icon [icon]="icons.falFilter" class="mr-1"></fa-icon>
      {{ "ui.charts.filters" | translate }}
    </button>
  </div>
  <div class="filters side-filter" *ngIf="isShowFilter">
    <div class="container">
      <button class="close pt-2 pl-2" (click)="showFilter()"
      aria-label="Close filter bar">
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </button>
    </div>
    <app-atlas-ftth-filter
    [lastQuarterDataAvailable]="atlasFtthSetvice.lastQuarterDataAvailable"
    (selectedAreaChange)="searchArea($event)"
    ></app-atlas-ftth-filter>
  </div>
</div>
<app-atlas-ftth-legend></app-atlas-ftth-legend>
<app-atlas-ftth-description [data]="dataDescription"></app-atlas-ftth-description>
